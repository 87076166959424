import { Icon } from "@iconify/react/dist/iconify.js";
import { Form, Link, NavLink as RemixNavLink, Outlet, useFetcher, useLocation } from "@remix-run/react";
import { ReactNode, useEffect, useState } from "react";
import { useLocale } from "remix-i18next/react";

import logo_en from "~/assets/napaLogo_horizontal_darkBackground_en.svg";
import logo_fr from "~/assets/napaLogo_horizontal_darkBackground_fr.svg";
import { NapaShield } from "~/components/Logo";

const LOGOS = {
  en: logo_en,
  fr: logo_fr
}

import { useLocalization } from "~/hooks/localize";
export default function UnauthorizedLayout() {
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  const fetcher = useFetcher()
  const locale = useLocale();
  const { t, language } = useLocalization();
  useEffect(() => {
    setMenuOpen(false);
  }, [location?.pathname]);

  useEffect(() => {
    setMenuOpen(false);
  }, [fetcher]);
  return (
    <div>
      <header className="print:hidden sticky top-0 z-50 flex h-[80px] items-center justify-between bg-primary shadow-lg shadow-primary/20">
        <div />
        <div className="h-[40px]">
          <Link to="/">
            <img src={LOGOS[language]} className="max-h-full" alt="NAPA Rebates Logo" />
          </Link>
        </div>
        <button
          type="button"
          className="p-[18px] text-white"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Icon icon={menuOpen ? "mdi:close" : "mdi:menu"} fontSize={24} />
        </button>
      </header>

      <div
        className={`fixed right-0 top-[80px] z-50 flex h-screen w-screen max-w-xl flex-col justify-between bg-primary px-6 py-4 pb-[80px] shadow-lg shadow-primary/20 transition-transform duration-200 ${menuOpen ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <div>
          <ul>
            <li>
              <NavLink to="/" icon="mdi:home">
                {t("home")}
              </NavLink>
            </li>
          </ul>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-4">
            <fetcher.Form
              method="post"
              action={`/changeLanguage?redirectTo=${location?.pathname}`}
            // action={`/changeLanguage?redirectTo=en`}
            >
              <button
                type="submit"
                name="lng"
                value={locale === "fr" ? "en" : "fr"}
                className="my-2 flex w-full gap-3 rounded bg-black/10 fill-white/40 px-5 py-4 tracking-wide text-white/95 hover:bg-black/20 hover:text-white/80"
              >
                {" "}
                <Icon icon="mdi:translate" fontSize={24} />
                {t("lng")}
              </button>
            </fetcher.Form>

          </div>
        </div>
      </div>
      <main className="mx-auto max-w-md p-4 md:p-10"><Outlet /></main>
    </div>

  )
}
function NavLink({
  to,
  children,
  icon,
}: {
  to: string;
  children: ReactNode;
  icon: string;
}) {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <RemixNavLink
      to={to}
      className={({ isActive }) =>
        `my-2 flex gap-3 rounded px-5 py-4 tracking-wide ${isActive
          ? "bg-black/30  text-[#cfd9fa]"
          : "bg-black/10  text-white/95 hover:bg-black/20 hover:text-white/80"
        }`
      }
    >
      {icon && (
        <Icon
          icon={icon}
          fontSize={24}
          className={isActive ? "text-secondary" : "text-white/40"}
        />
      )}
      {children}
    </RemixNavLink>
  );
}
